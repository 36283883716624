<script setup lang="ts">
const props = defineProps<{
  title?: string;
  type: "text" | "select" | "radio" | "checkbox" | "textarea" | "email" | "url" | "number" | "date" | "password" | "funds";
  required?: boolean;
  placeholder?: string;
  options?: string[];
  customValidation?: (value: string | boolean) => Promise<string>;
  error?: string;
  autofocus?: boolean;
}>();

const model = defineModel<string | boolean>({
  default: "",
});

const errorModel = defineModel<string>("error", {
  default: "",
});

const element = ref<HTMLObjectElement>();

const checkboxValues = ref<string[]>([]);

watch(checkboxValues, (checkboxValues) => {
  if (props.type === "checkbox") {
    model.value = checkboxValues.join(", ");
  }
}, { deep: true });

watch(model, (model) => {
  if (props.type === "checkbox" && typeof model === "string") {
    checkboxValues.value = model.split(", ").filter(Boolean);
  }
}, { immediate: true });

const inputName = (Math.random() + 1).toString(36).substring(7);

const validate = async (event: Event) => {
  const eventTarget = event.target as HTMLInputElement | null;

  if (props.customValidation) {
    eventTarget?.setCustomValidity(await props.customValidation(eventTarget.value));
  } else if (props.type === "checkbox" && props.required) {
    eventTarget?.setCustomValidity(model.value ? "Please select a value" : "");
  }

  errorModel.value = eventTarget?.checkValidity() ? "" : eventTarget?.validationMessage ?? "";
};

const invalid = (event: Event) => {
  errorModel.value = (event.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement).validationMessage;
};
</script>

<template>
  <div
    class="flex flex-col gap-1 rounded-lg bg-white px-7 focus-within:ring-1"
    :class="{ 'py-3': title, 'py-7': !title }"
  >
    <div
      v-if="title"
      class="text-xl text-navy/40"
    >
      {{ title }}{{ required ? '*' : '' }}
    </div>
    <input
      v-if="['text', 'email', 'number', 'date', 'url', 'password', 'funds'].includes(type)"
      ref="element"
      v-model="model"
      :type="type === 'funds' ? 'number' : type"
      :required="!!required"
      class="w-full border-0 p-0 text-[length:inherit] placeholder:text-navy/40 focus:ring-0"
      :placeholder="placeholder"
      :autofocus="autofocus"
      @input="validate"
      @click="
        (event) => {
          if (
            type === 'url'
            && event.target
            && (event.target as HTMLInputElement).value === ''
          )
            (event.target as HTMLInputElement).value = 'https://';
        }
      "
      @invalid.prevent="invalid"
    />
    <select
      v-if="type === 'select'"
      ref="element"
      v-model="model"
      class="border-0 text-[length:inherit] focus:ring-0"
      @input="validate"
      @invalid.prevent="invalid"
    >
      <option
        v-for="option in options"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </select>
    <div
      v-if="type === 'radio' || type === 'checkbox'"
      class="flex flex-col gap-1"
      :class="{ 'mt-2': title }"
    >
      <label
        v-for="option in options"
        :key="option"
        class="flex cursor-pointer items-center gap-5"
      >
        <input
          v-if="type === 'radio'"
          ref="element"
          v-model="model"
          :type="type"
          :value="option"
          :name="inputName"
          class="size-5 cursor-pointer appearance-none rounded-md border-0 bg-white text-blue ring-1 ring-black/20 ring-offset-1 before:opacity-0 before:transition-opacity checked:ring-blue checked:before:opacity-100 focus:ring-1 focus:ring-blue focus:ring-offset-1"
          @input="validate"
          @invalid.prevent="invalid"
        />
        <input
          v-if="type === 'checkbox'"
          ref="element"
          :checked="checkboxValues.includes(option)"
          :type="type"
          :value="option"
          class="size-5 cursor-pointer appearance-none rounded-md border-0 bg-white text-blue ring-1 ring-black/20 ring-offset-1 before:opacity-0 before:transition-opacity checked:ring-blue checked:before:opacity-100 focus:ring-1 focus:ring-blue focus:ring-offset-1"
          @change="(event) => {
            if ((<HTMLInputElement>event.target)?.checked) {
              checkboxValues.push(option);
            }
            else {
              checkboxValues.splice(checkboxValues.indexOf(option), 1);
            }
            validate(event);
          }"
          @invalid.prevent="invalid"
        />
        {{ option }}
      </label>
    </div>
    <textarea
      v-if="type === 'textarea' && typeof model === 'string'"
      ref="element"
      v-model="model"
      :required="!!required"
      class="h-[100px] border-0 p-0 text-[length:inherit] placeholder:text-navy/40 focus:ring-0"
      :placeholder="placeholder"
      @input="validate"
      @invalid.prevent="invalid"
    />
    <div
      v-if="error"
      class="text-sm text-[red]"
    >
      {{ error }}
    </div>
  </div>
</template>
